import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";

const Inicio = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.icausas.live/dashboard")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!data) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" align="center" color="error" sx={{ mt: 4 }}>
          Erro ao carregar os dados. Por favor, tente novamente mais tarde.
        </Typography>
      </Container>
    );
  }

  // Dados com fallback
  const mostPopularCourse = data?.summary?.most_popular_course || {
    course_name: "N/A",
    student_count: 0,
  };

  const topCourses = data?.top_courses || [];

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          color: "primary.main",
          mb: 2,
          fontWeight: "bold",
        }}
      >
        Resumo do Sistema
      </Typography>
      <Divider sx={{ mb: 3, borderColor: "primary.light", borderWidth: 2 }} />

      <Grid container spacing={3}>
        {/* Card 1: Curso Mais Popular */}
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              padding: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 4,
              background:
                "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <StarIcon sx={{ fontSize: 40, color: "gold" }} />
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  Curso Mais Popular
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}
                >
                  {mostPopularCourse.course_name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                >
                  {mostPopularCourse.student_count} alunos
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Títulos e Cards */}
        <Grid item xs={12}>
          <Typography
            variant="h5"
            align="center"
            sx={{ color: "primary.main", mb: 2 }}
          >
            Cursos Mais Populares
          </Typography>
          <Divider sx={{ mb: 2, borderColor: "primary.light" }} />
        </Grid>

        {topCourses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={4}
              sx={{
                padding: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                borderRadius: 4,
                background: "linear-gradient(135deg, #e0f7fa, #80deea)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SchoolIcon sx={{ fontSize: 35, color: "primary.main" }} />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: "primary.dark", fontWeight: "bold" }}
                >
                  {course.course_name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {course.student_count} alunos
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Inicio;
