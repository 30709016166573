import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
    ScatterChart,
    Scatter,
    CartesianGrid,
    Tooltip,
    XAxis,
    YAxis, ResponsiveContainer
} from "recharts";
import Typography from '@mui/material/Typography';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Chart = ({ data }) => {
    return (
        <ResponsiveContainer width="80%" height={400}>
            <ScatterChart
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                }}
            >
                <CartesianGrid />
                <XAxis 
                    type="number" 
                    dataKey="mc" 
                    name="Média" 
                    label={{ value: 'Média das Notas', position: 'insideBottom', offset: -5 }}
                />
                <YAxis 
                    type="number" 
                    dataKey="PendingWorkload" 
                    name="CH. Obrigatória Pendente"
                    label={{ value: 'CH Obrigatória Pendente', angle: -90, position: 'insideLeft', dy: +120, dx: -10 }}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={data} fill="#8884d8" />
            </ScatterChart>
        </ResponsiveContainer>
    );
};

const App = () => {
    const [data, setData] = useState([]);
    const [curso, setCurso] = useState({ nome: 'cc', valor: 1 });
    const [anoInicio, setAnoInicio] = useState('2018.2');
    const [anoFim, setAnoFim] = useState('2021.1');
    const [materia, setMateria] = useState('pf');
    const [materias, setMaterias] = useState([{ chave: 'pf', nome: 'Programação Funcional' }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://dev3.masu.email/api/teste_student/student/workload/${curso.valor}/${anoInicio}/${anoFim}`);
                setData(response.data);
            } catch (error) {
                console.error('Erro ao obter dados da API', error);
            }
        };
        fetchData();
    }, [anoInicio, anoFim, curso, materia]);

    const anos = ['2011.1', '2011.2','2012.1', '2012.2', '2013.1', '2013.2', '2014.1', '2014.2', '2015.1', '2015.2', '2016.1', '2016.2', '2017.1', '2017.2', '2018.1', '2018.2', '2019.1', '2019.2', '2020.1', '2020.2', '2021.1', '2021.2'];
    const cursos = [{ nome: 'cc', valor: 1 }, { nome: 'ec', valor: 2 }, { nome: 'si', valor: 3 }];

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Curso</InputLabel>
                    <Select
                        value={curso}
                        onChange={e => setCurso(e.target.value)}
                        label="Curso"
                        renderValue={(selected) => selected.nome}
                    >
                        {cursos.map((item) => (
                            <MenuItem key={item.valor} value={item}>{item.nome}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Início</InputLabel>
                    <Select value={anoInicio} onChange={e => setAnoInicio(e.target.value)} label="Início">
                        {anos.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Fim</InputLabel>
                    <Select value={anoFim} onChange={e => setAnoFim(e.target.value)} label="Fim">
                        {anos
                            .filter(item => item >= anoInicio)
                            .map((item) => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {`Relação entre Média das Notas e Carga Horária Obrigatória Pendente para o Curso de ${curso.nome.toUpperCase()} entre ${anoInicio} e ${anoFim}`}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Chart data={data} />
            </Box>
        </div>
    );
};

export default App;
